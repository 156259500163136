import React from 'react'
import Modal from './Modal'

function NotFound() {
  return (
    <Modal title={"404 - Page not found"} message={"Dang, that's a shame."}/>
  )
}

export default NotFound
