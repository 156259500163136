import React from 'react'

function FootNote() {
  return (
    <div className="foot-note">
      <p className="foot-note__text">
        (This site made with | by Louis Earl)
      </p>
    </div>
  )
}

export default FootNote
